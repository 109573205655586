import { makeAutoObservable } from "mobx";
import getStoreLocatorData from "../apis/store-locator-api";
export class ShopLocatorStore {
    constructor() {
        this.storesData = null;
        this.storesDataSearch = null;
        this.storesDataRender = null;
        this.detailedStoreData = null;
        this.activeStoreNumber = "";
        this.isLoading = false;
        this.storesDataCheckout = null;
        this.localBusinessData = null;
        makeAutoObservable(this);
    }
    setLocalBusinessData(localBusinessData) {
        this.localBusinessData = localBusinessData;
    }
    cleanLocalBusinessData() {
        this.localBusinessData = null;
    }
    getStoreFromLocal(inputVal) {
        var _a;
        if (!inputVal)
            return this.storesData;
        return (_a = this.storesData) === null || _a === void 0 ? void 0 : _a.filter((store) => {
            const regexp = new RegExp(inputVal, "gi");
            return regexp.test(store.address) || regexp.test(store.town) || regexp.test(store.postalCode);
        });
    }
    async getStoresData(inputVal, isCheckout) {
        var _a, _b, _c;
        this.isLoading = true;
        // get data from locator API if storesData is null
        try {
            const res = await getStoreLocatorData(inputVal);
            if (res) {
                if (isCheckout) {
                    this.storesDataCheckout = res.data;
                }
                else {
                    this.storesData = res.data;
                    this.storesDataRender = res.data;
                    this.storesDataSearch = res.data;
                    this.detailedStoreData = (_a = res.data) === null || _a === void 0 ? void 0 : _a[0];
                    this.activeStoreNumber = (_c = (_b = res.data) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.storeNumber;
                }
            }
            else {
                if (isCheckout) {
                    this.storesDataCheckout = null;
                }
                else {
                    this.storesData = null;
                    this.storesDataRender = null;
                    this.storesDataSearch = null;
                    this.detailedStoreData = null;
                    this.activeStoreNumber = null;
                }
            }
        }
        catch (e) {
            console.log(e);
        }
        this.isLoading = false;
    }
    changeActiveStore(clickedStoreNumber) {
        var _a;
        const activeStore = (_a = this.storesDataRender) === null || _a === void 0 ? void 0 : _a.find((store) => Number(store.storeNumber) === Number(clickedStoreNumber));
        if (activeStore) {
            this.detailedStoreData = activeStore;
            this.activeStoreNumber = activeStore.storeNumber;
        }
    }
    filterStores(isKidsChecked, isTailerChecked) {
        var _a, _b, _c, _d;
        if ((_a = this.storesDataRender) === null || _a === void 0 ? void 0 : _a.length) {
            // reset data before filter render data
            this.storesDataRender = this.storesDataSearch;
            if (isKidsChecked) {
                this.storesDataRender = ((_b = this.storesDataRender) === null || _b === void 0 ? void 0 : _b.filter((store) => store.hasKids)) || [];
            }
            if (isTailerChecked) {
                this.storesDataRender = ((_c = this.storesDataRender) === null || _c === void 0 ? void 0 : _c.filter((store) => store.hasTailor)) || [];
            }
            if ((_d = this.storesDataRender) === null || _d === void 0 ? void 0 : _d[0]) {
                this.detailedStoreData = this.storesDataRender[0];
                this.activeStoreNumber = this.storesDataRender[0].storeNumber;
            }
            else {
                this.storesDataRender = null;
                this.detailedStoreData = null;
                this.activeStoreNumber = "";
            }
        }
    }
}
const shopLocatorStore = new ShopLocatorStore();
export default shopLocatorStore;
