import { useSocial } from "@/hooks/useSocial";
import { useLayout } from "@/lib/preFetch/preFetchLayout";
import metaJson from "@/lib/prismicio/prismic-meta.json";
import { LOGO_IMG_CLOUDINARY } from "@/util/constants";
import { getDomain } from "@jog/shared";
import { asImageSrc, asText } from "@prismicio/helpers";
import { observer } from "mobx-react";
import NextHead from "next/head";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useStores } from "stores";
//LocalBusiness structured data
const localBusinessSD = (localBusinessData) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    if (!localBusinessData)
        return null;
    const domain = getDomain();
    return {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "@id": `${domain || ""}/#LocalBusiness`,
        name: localBusinessData.name,
        address: {
            "@type": "PostalAddress",
            streetAddress: (_a = localBusinessData.address) === null || _a === void 0 ? void 0 : _a.streetAddress,
            addressLocality: (_b = localBusinessData.address) === null || _b === void 0 ? void 0 : _b.addressLocality,
            addressRegion: (_c = localBusinessData.address) === null || _c === void 0 ? void 0 : _c.addressRegion,
            postalCode: (_d = localBusinessData.address) === null || _d === void 0 ? void 0 : _d.postalCode,
            addressCountry: (_e = localBusinessData.address) === null || _e === void 0 ? void 0 : _e.addressCountry,
        },
        geo: {
            "@type": "GeoCoordinates",
            latitude: (_f = localBusinessData.geo) === null || _f === void 0 ? void 0 : _f.latitude,
            longitude: (_g = localBusinessData.geo) === null || _g === void 0 ? void 0 : _g.longitude,
        },
        telephone: localBusinessData.telephone,
        openingHoursSpecification: (_j = (_h = localBusinessData.openingHoursSpecification) === null || _h === void 0 ? void 0 : _h.map((item) => ({
            "@type": "OpeningHoursSpecification",
            dayOfWeek: item.dayOfWeek,
            opens: item.opens,
            closes: item.closes,
        }))) !== null && _j !== void 0 ? _j : "",
        image: localBusinessData.image,
        url: localBusinessData.url,
    };
};
// Product structured data
const productSD = (productStructuredData) => {
    if (!productStructuredData)
        return null;
    const domain = getDomain();
    return {
        "@context": "https://schema.org",
        "@type": "Product",
        "@id": `${domain || ""}/#Product`,
        name: productStructuredData.name,
        image: productStructuredData.images,
        offers: {
            "@type": "Offer",
            price: productStructuredData.price,
            priceCurrency: productStructuredData.currency,
            availability: "https://schema.org/InStock",
            itemCondition: "https://schema.org/NewCondition",
        },
        description: productStructuredData.description,
        color: productStructuredData.color,
        brand: productStructuredData.brand,
        sku: productStructuredData.sku,
        size: productStructuredData.size,
        material: productStructuredData.material,
    };
};
// Organization+logo structured data
const organizationLogo = (logo, socialUrls, title) => {
    return {
        "@context": "https://schema.org",
        "@type": "Organization",
        "@id": `${getDomain()}/Organization#`,
        url: `${getDomain()}/`,
        name: title,
        logo: logo,
        sameAs: socialUrls,
    };
};
const preconnectEndpointList = () => {
    /**
     * The preconnect directive allows the browser to setup early connections before an HTTP request
     * is actually sent to the server.
     * This includes DNS lookups, TLS negotiations, TCP handshakes
     */
    return [
        process.env.NEXT_PUBLIC_CTP_ENDPOINT,
        "https://dev.visualwebsiteoptimizer.com",
        "https://res.cloudinary.com",
        "https://connect.facebook.net",
        "https://www.facebook.com",
        "https://fonts.googleapis.com",
        "https://www.google-analytics.com",
        "https://www.google.com",
        "https://www.googletagmanager.com",
        "https://www.gstatic.com",
        "https://in.hotjar.com",
        "https://stats.g.doubleclick.net",
        "https://h.clarity.ms/",
        "https://static.cdn.prismic.io",
        "https://images.prismic.io",
    ];
};
const HeadComponent = ({ title, isHomePage, description, robots, headAttributes, pathname, productStructuredData, alternate, }) => {
    var _a;
    const meta = useMemo(() => metaJson, []);
    const layout = useLayout();
    const { shopLocatorStore: { localBusinessData }, } = useStores();
    const router = useRouter();
    const domain = getDomain();
    const _pathname = useMemo(() => {
        if (pathname)
            return pathname;
        const { asPath, defaultLocale, locale, query } = router;
        const path = query["pretty-path"] || asPath; // start with `/`
        return defaultLocale === locale ? `${path}` : `/${locale}${path}`; // start with `/`
    }, [pathname, router]);
    const currentPath = useMemo(() => `${domain}${_pathname}`, [domain, _pathname]);
    const socials = useSocial();
    const productStructuredDataJSON = useMemo(() => {
        const data = productSD(productStructuredData);
        return data ? JSON.stringify(data) : null;
    }, [productStructuredData]);
    const localBusinessDataJSON = useMemo(() => {
        const data = localBusinessSD(localBusinessData);
        return data ? JSON.stringify(data) : null;
    }, [localBusinessData]);
    const organizationJSON = useMemo(() => {
        var _a;
        if (typeof window !== "undefined" && window.location.pathname === "/") {
            return JSON.stringify(organizationLogo(asImageSrc((_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.logo) || "", socials.map((i) => i.href), meta.structured_data_organization_title));
        }
        return null;
    }, [layout, meta.structured_data_organization_title, socials]);
    return (<NextHead>
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
            <link rel="manifest" href="/site.webmanifest"/>
            <title>
                {title ||
            ((headAttributes === null || headAttributes === void 0 ? void 0 : headAttributes.meta_tag_title) && asText(headAttributes === null || headAttributes === void 0 ? void 0 : headAttributes.meta_tag_title)) ||
            meta.title ||
            "Welcome to Jeans Centre"}
            </title>
            {alternate === null || alternate === void 0 ? void 0 : alternate.map(({ locale, url }) => (<link rel="alternate" href={url} key={"alternate" + locale + url} hrefLang={locale}/>))}
            {(_a = meta.alternate) === null || _a === void 0 ? void 0 : _a.map(({ href, lang }, index) => (<link key={index} rel="alternate" href={href} hrefLang={lang}/>))}
            <link rel="canonical" href={currentPath}/>
            <meta name="description" content={description ||
            ((headAttributes === null || headAttributes === void 0 ? void 0 : headAttributes.meta_tag_description) && asText(headAttributes === null || headAttributes === void 0 ? void 0 : headAttributes.meta_tag_description)) ||
            meta.description ||
            "Welcome to Jeans Centre"}/>
            <meta name="robots" content={process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
            ? robots ||
                ((headAttributes === null || headAttributes === void 0 ? void 0 : headAttributes.meta_tag_robots) && asText(headAttributes === null || headAttributes === void 0 ? void 0 : headAttributes.meta_tag_robots)) ||
                meta.robots ||
                "index,follow"
            : "noindex, nofollow"}/>
            <meta property="og:title" content={meta.og_title || "Jeans Centre | Dé webshop van jouw jeans specialist"}/>
            <meta property="og:site_name" content={meta.og_site_name || "jeanscentre.nl"}/>
            <meta property="og:image" content={meta.og_image || LOGO_IMG_CLOUDINARY}/>
            <meta property="og:description" content={meta.og_description ||
            "Denk je aan jeans, dan denk je aan Jeans Centre! Al 40 jaar is Jeans Centre dé specialist op het gebied van jeans. We zijn uitgegroeid tot een brand met bijna 100 winkels en een online shop met volop keuze in jeans en eigentijdse fashion."}/>
            {isHomePage && <meta name="facebook-domain-verification" content="o8mscvc5og6nujhx6puki2954cp91f"/>}
            {preconnectEndpointList().map((href, index) => (<link key={index} rel="preconnect" href={href} crossOrigin=""/>))}
            {organizationJSON && <script type="application/ld+json">{organizationJSON}</script>}
            {productStructuredDataJSON && <script type="application/ld+json"> {productStructuredDataJSON}</script>}
            {localBusinessDataJSON && <script type="application/ld+json"> {localBusinessDataJSON}</script>}
        </NextHead>);
};
export const Head = observer(HeadComponent);
